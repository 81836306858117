import {
  isArrayPropertyWithVerify,
  isPropertyWithVerify,
  validateNotNull,
  validateOptional,
} from '../../../guards';
import { Authority, validateAuthorityOf } from '../../authority';
import {
  CatalogueBrandInfo,
  validateCatalogueBrandInfo,
} from './titleType/catalogueBrandInfo';
import { CatalogueTitleInfo } from './titleType/catalogueTitleInfo';
import { AlternateName, validateAlternateName } from './alternateName';

export interface Brand {
  catalogue: Authority<CatalogueTitleInfo>;
  alternateNames: AlternateName[];
}

export const validateBrand = (argument: any) => {
  validateNotNull(argument, `Invalid Brand type, object is null`);
  isPropertyWithVerify(
    argument.catalogue,
    validateAuthorityOf('brand', validateCatalogueBrandInfo),
    'Invalid Brand type, catalogue is not a CatalogueBrandInfo'
  );
  isArrayPropertyWithVerify(
    argument.alternateNames,
    validateAlternateName,
    'Invalid Brand type, alternateNames is not a AlternateName[]'
  );
  return true;
};

export interface BrandOptional {
  catalogue: Authority<CatalogueBrandInfo | null>;
  alternateNames: AlternateName[];
}

export const validateBrandOptional = (argument: any) => {
  validateNotNull(argument, `Invalid BrandOptional type, object is null`);
  isPropertyWithVerify(
    argument.catalogue,
    validateAuthorityOf('brand', validateOptional(validateCatalogueBrandInfo)),
    'Invalid BrandOptional type, catalogue is not a CatalogueBrandInfo'
  );
  isArrayPropertyWithVerify(
    argument.alternateNames,
    validateAlternateName,
    'Invalid BrandOptional type, alternateNames is not a AlternateName[]'
  );
  return true;
};
