import {
  isNonEmptyString,
  isPropertyWithVerify,
  validateNotNull,
} from 'domain/guards';
import { StartDateRange, validateStartDateRangeWithUnitType } from '../date';

export interface Scheduling {
  txDate?: StartDateRange;
  furtherInformation?: string;
  live?: boolean;
}

export const validateScheduling = (argument: Record<string, any>) => {
  validateNotNull(argument, `Invalid Scheduling type, object is null`);

  if (argument.txDate != null) {
    isPropertyWithVerify(
      argument.txDate,
      validateStartDateRangeWithUnitType,
      `Invalid Scheduling.txDate type, should be StartDateRange`
    );
  }

  if (argument.furtherInformation != null) {
    isPropertyWithVerify(
      argument.furtherInformation,
      isNonEmptyString,
      `Invalid Scheduling.furtherInformation type, should be NonEmptyString`
    );
  }

  if (argument.live != null) {
    isPropertyWithVerify(
      argument.live,
      (val) => typeof val === 'boolean',
      `Invalid Scheduling.live type, should be boolean`
    );
  }

  return true;
};
