import {
  isPropertyWithVerify,
  isBoolean,
  isNonEmptyString,
  validateIsObject,
  validateNotNull,
} from '../guards';

export interface AgreementsAndRegulatory {
  certification?: string;
  genre?: OfcomGenre;
  outsideOfLondon?: boolean;
}

export const validateAgreementsAndRegulatory = (argument: any) => {
  validateNotNull(argument, 'Invalid AgreementsAndRegulatory, value is null');
  validateIsObject(
    argument,
    'Invalid AgreementsAndRegulatory, value is not an object'
  );
  if (argument.certification != null) {
    isPropertyWithVerify(
      argument.certification,
      isNonEmptyString,
      'Invalid AgreementsAndRegulatory.certification, should be string'
    );
  }
  if (argument.genre != null) {
    isPropertyWithVerify(
      argument.genre,
      validateOfcomGenre,
      'Invalid AgreementsAndRegulatory.genre, should be OfcomGenre'
    );
  }
  if (argument.outsideOfLondon != null) {
    isPropertyWithVerify(
      argument.outsideOfLondon,
      isBoolean,
      'Invalid AgreementsAndRegulatory.outsideOfLondon, should be boolean'
    );
  }
  return true;
};

export interface OfcomGenre {
  superGenre: string;
  subGenre: string;
}

export const validateOfcomGenre = (argument: any) => {
  validateNotNull(argument, 'Invalid OfcomGenre, value is null');
  isPropertyWithVerify(
    argument.superGenre,
    isNonEmptyString,
    'Invalid OfcomGenre.superGenre, should be string'
  );
  isPropertyWithVerify(
    argument.subGenre,
    isNonEmptyString,
    'Invalid OfcomGenre.subGenre, should be string'
  );
  return true;
};
