/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-shadow */
import {
  hasNonEmptyStringProperty,
  isPropertyWithVerify,
  validateNotNull,
  validateOptional,
} from './guards';
import { Authority, validateAuthorityOf } from './igniteTitle/authority';
import {
  SeriesDetails,
  validateSeriesDetails,
} from './igniteTitle/details/catalogue/titleType/seriesDetails';
import {
  Genre,
  SubGenre,
  validateGenre,
  validateSubGenre,
} from './igniteTitle/details/catalogue/genre';
import {
  CatalogueTitleInfo,
  validateCatalogueTitleInfo,
} from './igniteTitle/details/catalogue/titleType/catalogueTitleInfo';
import {
  CatalogueBrandInfo,
  validateCatalogueBrandInfo,
} from './igniteTitle/details/catalogue/titleType/catalogueBrandInfo';

export interface AthenaSeries {
  brand: Authority<CatalogueTitleInfo>;
  seriesDetails: Authority<SeriesDetails>;
  genre: Authority<Nullable<Genre>>;
  subGenres: Authority<SubGenre[]>;
  displayName: string;
}

export const validateAthenaSeries = (argument: Record<string, any>) => {
  validateNotNull(argument, `Invalid AthenaSeries type, object is null`);
  isPropertyWithVerify(
    argument.brand,
    validateAuthorityOf('brand', validateCatalogueBrandInfo),
    'Invalid AthenaSeries type, brand is not a CatalogueBrandInfo'
  );
  isPropertyWithVerify(
    argument.seriesDetails,
    validateAuthorityOf('seriesDetails', validateSeriesDetails),
    'Invalid AthenaSeries type, seriesDetails is not a SeriesDetails'
  );
  isPropertyWithVerify(
    argument.genre,
    validateAuthorityOf('genre', validateOptional(validateGenre)),
    'Invalid AthenaSeries type, genre is not a Genre'
  );
  isPropertyWithVerify(
    argument.subGenres,
    validateAuthorityOf('subGenres', validateSubGenres),
    'Invalid AthenaSeries type, subGenres is not a SubGenre[]'
  );
  hasNonEmptyStringProperty(argument, 'AthenaSeries', 'displayName');
  return true;
};

const validateSubGenres = (argument: unknown) => {
  validateNotNull(argument, `Invalid subGenres type, object is null`);
  if (!(Array.isArray(argument) && argument.every(validateSubGenre))) {
    throw new TypeError(
      'Invalid SeriesPlusData type, subgenres was not an array of subgenre'
    );
  }
  return true;
};

export interface AthenaSpecial {
  brand: Authority<CatalogueBrandInfo | null>;
  title: Authority<CatalogueTitleInfo>;
  genre: Authority<Nullable<Genre>>;
  subGenres: Authority<SubGenre[]>;
}

export const validateAthenaSpecial = (argument: Record<string, any>) => {
  validateNotNull(argument, `Invalid AthenaSpecial type, object is null`);
  isPropertyWithVerify(
    argument.brand,
    validateAuthorityOf('brand', validateOptional(validateCatalogueBrandInfo)),
    'Invalid AthenaSpecial type, brand is not a CatalogueBrandInfo'
  );
  isPropertyWithVerify(
    argument.title,
    validateAuthorityOf('catalogueTitleInfo', validateCatalogueTitleInfo),
    'Invalid AthenaSpecial type, title is not a CatalogueTitleInfo'
  );
  isPropertyWithVerify(
    argument.genre,
    validateAuthorityOf('genre', validateOptional(validateGenre)),
    'Invalid AthenaSpecial type, genre is not a Genre'
  );
  isPropertyWithVerify(
    argument.subGenres,
    validateAuthorityOf('subGenres', validateSubGenres),
    'Invalid AthenaSpecial type, subGenres is not a SubGenre[]'
  );
  return true;
};

export interface AthenaFilm {
  title: Authority<CatalogueTitleInfo>;
  genre: Authority<Nullable<Genre>>;
  subGenres: Authority<SubGenre[]>;
}

export const validateFilmPlusData = (argument: Record<string, any>) => {
  validateNotNull(argument, `Invalid AthenaFilm type, object is null`);
  isPropertyWithVerify(
    argument.title,
    validateAuthorityOf('catalogueTitleInfo', validateCatalogueTitleInfo),
    'Invalid AthenaFilm type, title is not a CatalogueTitleInfo'
  );
  isPropertyWithVerify(
    argument.genre,
    validateAuthorityOf('genre', validateOptional(validateGenre)),
    'Invalid AthenaFilm type, genre is not a Genre'
  );
  isPropertyWithVerify(
    argument.subGenres,
    validateAuthorityOf('subGenres', validateSubGenres),
    'Invalid AthenaFilm type, subGenres is not a SubGenre[]'
  );
  return true;
};

export interface CatalogueBrandResponse {
  ccid: string;
  name: string;
  genre: Nullable<Genre>;
  subgenres: SubGenre[];
}

export const validateCatalogueBrandResponse = (
  argument: Record<string, any>
) => {
  validateNotNull(
    argument,
    `Invalid CatalogueBrandResponse type, object is null`
  );
  hasNonEmptyStringProperty(argument, 'CatalogueBrandResponse', 'ccid');
  hasNonEmptyStringProperty(argument, 'CatalogueBrandResponse', 'name');
  isPropertyWithVerify(
    argument.genre,
    validateOptional(validateGenre),
    'Invalid CatalogueBrandResponse type, genre is not a Genre'
  );
  isPropertyWithVerify(
    argument.subgenres,
    validateSubGenres,
    'Invalid CatalogueBrandResponse type, subgenres is not a SubGenre[]'
  );
  return true;
};

export interface AthenaBrand {
  brandCcid: string;
  brandName: string;
  genre: Nullable<Genre>;
  subGenres: SubGenre[];
}

export const validateAthenaBrand = (argument: Record<string, any>) => {
  validateNotNull(argument, `Invalid athenaBrand type, object is null`);
  hasNonEmptyStringProperty(argument, 'athenaBrand', 'brandCcid');
  hasNonEmptyStringProperty(argument, 'athenaBrand', 'brandName');
  return true;
};
