import { isTypeFromValidate } from 'utils/isTypeFromValidate';
import {
  FilmType,
  SeriesType,
  SpecialType,
  TitleType,
  TitleTypes,
  validateSeriesType,
  validateSpecialType,
} from './details/catalogue/titleType/titleType';
import { IgniteTitle, IgniteTitleMin } from './titles';

interface Names {
  primary: string;
  sub?: string;
}

const getTitleNamesFromSpecial = (titleType: TitleType) => {
  const brandName = (titleType as SpecialType).brand.catalogue?.value?.name;
  const titleName = (titleType as SpecialType).title.catalogue?.value?.name;

  const primary = brandName ?? titleName;
  const sub = brandName ? titleName : undefined;

  return { primary, sub };
};

const getTitleNamesFromSeries = (titleType: TitleType) => {
  const seriesType = titleType as SeriesType;
  const primary = seriesType.brand.catalogue?.value?.name;
  const sub = seriesType.seriesDetails.value?.seriesNumber
    ? `Series ${seriesType.seriesDetails.value?.seriesNumber}`
    : seriesType.seriesDetails.value?.seriesName;
  return { primary, sub };
};

export const extractTitleNames: (title: IgniteTitle) => Names = (
  title: IgniteTitle
) => {
  const { titleType } = title.detail.catalogue;
  if (isTypeFromValidate<TitleType>(validateSpecialType as any, titleType)) {
    return getTitleNamesFromSpecial(titleType);
  }

  if (isTypeFromValidate<TitleType>(validateSeriesType as any, titleType)) {
    return getTitleNamesFromSeries(titleType);
  }

  const primary = (titleType as FilmType).title.catalogue?.value?.name;
  return { primary, sub: '' };
};

const getMinTitleNamesFromSpecial = (title: IgniteTitleMin) => {
  const brandName = title.name;
  const titleName = title.subName;

  const primaryName = (brandName ?? titleName) as string;
  const subName = brandName ? titleName : undefined;

  return { primaryName, subName };
};

export const extractMinTitleNames: (title: IgniteTitleMin) => {
  primaryName: string;
  subName: string | undefined;
} = (title: IgniteTitleMin) => {
  const { titleType } = title;
  if (titleType === TitleTypes.SPECIAL) {
    return getMinTitleNamesFromSpecial(title);
  }

  if (titleType === TitleTypes.SERIES) {
    return { primaryName: title.name as string, subName: title.subName };
  }

  const primaryName = title.name as string;
  return { primaryName, subName: '' };
};
