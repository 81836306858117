/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable consistent-return */
/* eslint-disable no-use-before-define */
/* eslint-disable no-shadow */
/* eslint-disable no-console */
import { ProjectState, ProjectStatus } from 'features/ProjectStatus';
import { DeliverableStatus } from 'features/Deliverables';
import {
  Broadcaster,
  Producer,
  validateBroadcaster,
  validateProducer,
} from '../business';
import {
  Country,
  Language,
  validateCountry,
  validateLanguage,
} from '../datasets';
import {
  hasNonEmptyStringProperty,
  isPropertyWithVerify,
  isNonEmptyString,
  validateNotNull,
  isArrayPropertyWithVerify,
  isNonEmptyArrayPropertyWithVerify,
} from '../guards';
import { ArchivalMetadata, validateArchival } from './archival';
import { Authority } from './authority';
import {
  Catalogue,
  CatalogueBuilder,
  validateCatalogue,
} from './details/catalogue/catalogue';
import { Genre, SubGenre } from './details/catalogue/genre';
import { TimeSlots } from './details/catalogue/timeslots';
import { TitleType } from './details/catalogue/titleType/titleType';
import {
  ProductionDateRange,
  StartDateRange,
  validateStartDateRangeWithUnitType,
} from './details/date';
import { Finance, validateFinance } from './details/finance/finance';
import { FinanceBuilder } from './details/finance/financeBuilder';
import { Note, validateNote } from './notes';
import { ProducerInfo } from './producerInfo';
import { ProductionStatus, validateProductionStatus } from './productionStatus';
import { validateProjectState, validateProjectStatus } from './projectStatus';
import { UserInfo, validateUserInfo } from './userInfo';
import {
  AgreementsAndRegulatory,
  validateAgreementsAndRegulatory,
} from './agreementsAndRegulatory';
import { Rights, validateRights } from './dealTerms';
import {
  Scheduling,
  validateScheduling,
} from './details/scheduling/scheduling';
import { SchedulingBuilder } from './details/scheduling/schedulingBuilder';

export interface TitleDetails {
  catalogue: Catalogue;
  finance: Finance;
  team: string;
  teamType: string;
  countriesOfOrigin: Country[];
  languages: Language[];
  producers: Producer[];
  producerInfo?: ProducerInfo;
  broadcasters: Broadcaster[];
  destinations: string[];
  targetDate?: string;
  scheduling: Scheduling;
  deliveryDate?: StartDateRange;
  initiatedBy?: string;
  agreementsAndRegulatory: AgreementsAndRegulatory;
  rights: Rights;
}

export interface TitleDetailBuilderProps {
  titleType: TitleType;
  genre: Authority<Nullable<Genre>>;
  subGenres: Authority<SubGenre[]>;
  team: string;
  teamType: string;
  countriesOfOrigin: Country[];
  languages: Language[];
  producers: Producer[];
  broadcasters: Broadcaster[];
  producerInfo?: ProducerInfo;
  destinations?: string[];
  targetDate?: string;
  scheduling?: Scheduling;
  deliveryDate?: StartDateRange;
  timeSlots?: TimeSlots[];
  narrative?: string;
  talent?: string;
  productionYear: Authority<Nullable<ProductionDateRange>>;
  finance?: Finance;
  initiatedBy?: string;
  agreementsAndRegulatory?: AgreementsAndRegulatory;
  rights?: Rights;
}

export const titleDetailBuilder = ({
  titleType,
  genre,
  subGenres,
  team,
  teamType,
  countriesOfOrigin,
  languages,
  producers,
  broadcasters,
  producerInfo,
  destinations,
  targetDate,
  scheduling,
  deliveryDate,
  timeSlots,
  narrative,
  talent,
  productionYear,
  finance,
  initiatedBy,
  agreementsAndRegulatory,
  rights,
}: TitleDetailBuilderProps) => {
  const titleDetails: TitleDetails = {
    catalogue: CatalogueBuilder(
      titleType,
      genre,
      subGenres,
      productionYear,
      timeSlots,
      narrative,
      talent
    ),
    finance: FinanceBuilder(finance || {}),
    team,
    teamType,
    countriesOfOrigin,
    languages,
    producers,
    producerInfo,
    broadcasters,
    destinations: destinations || [],
    initiatedBy,
    agreementsAndRegulatory: agreementsAndRegulatory || {},
    rights: rights || { dealTerms: {} },
    scheduling: SchedulingBuilder(scheduling || {}),
  };

  if (targetDate != null) {
    titleDetails.targetDate = targetDate;
  }

  if (deliveryDate != null) {
    titleDetails.deliveryDate = deliveryDate;
  }

  return titleDetails;
};

export const validateTitleDetails = (argument: TitleDetails) => {
  validateNotNull(argument, `Invalid TitleDetails type, object is null`);

  isPropertyWithVerify(
    argument.catalogue,
    validateCatalogue,
    `Invalid TitleDetails.catalogue type, should be catalogue`
  );

  isPropertyWithVerify(
    argument.team,
    isNonEmptyString,
    `Invalid TitleDetails.team type, should be NonEmptyString`
  );

  isPropertyWithVerify(
    argument.teamType,
    isNonEmptyString,
    `Invalid TitleDetails.teamType type, should be NonEmptyString`
  );

  isArrayPropertyWithVerify(
    argument.countriesOfOrigin,
    validateCountry,
    `Invalid TitleDetails.countriesOfOrigin type, should be country`
  );

  isArrayPropertyWithVerify(
    argument.languages,
    validateLanguage,
    `Invalid TitleDetails.languages type, should be language`
  );

  isNonEmptyArrayPropertyWithVerify(
    argument.producers,
    validateProducer,
    `Invalid TitleDetails.producers type, should be NonEmptyArray of producers`
  );

  isArrayPropertyWithVerify(
    argument.broadcasters,
    validateBroadcaster,
    `Invalid TitleDetails.broadcasters type, should be array of broadcasters`
  );

  isPropertyWithVerify(
    argument.scheduling,
    validateScheduling,
    `Invalid TitleDetails.scheduling type, should be scheduling`
  );

  if (argument.finance != null) {
    isPropertyWithVerify(
      argument.finance,
      validateFinance,
      `Invalid TitleDetails.finance type, should be finance`
    );
  }

  if (argument.targetDate != null) {
    hasNonEmptyStringProperty(argument, 'TitleDetails', 'targetDate');
  }

  if (argument.deliveryDate != null) {
    isPropertyWithVerify(
      argument.deliveryDate,
      validateStartDateRangeWithUnitType,
      `Invalid TitleDetails.deliveryDate type, should be StartDateRange`
    );
  }

  if (argument.initiatedBy != null) {
    hasNonEmptyStringProperty(argument, 'TitleDetails', 'initiatedBy');
  }

  isPropertyWithVerify(
    argument.agreementsAndRegulatory,
    validateAgreementsAndRegulatory,
    'Invalid TitleDetails.agreementsAndRegulatory, should be an AgreementsAndRegulatory'
  );

  isPropertyWithVerify(
    argument.rights,
    validateRights,
    'Invalid TitleDetails.rights, should be a Rights'
  );

  return true;
};

export interface IgniteTitle {
  id: string;
  detail: TitleDetails;
  projectState: ProjectState;
  productionStatus?: ProductionStatus;
  archival?: ArchivalMetadata;
  createdBy: UserInfo;
  createdAt: string;
  modifiedBy: UserInfo;
  modifiedAt: string;
  notes: Note[];
  deliverablesStatus: Nullable<DeliverableStatus>;
  additionalContactEmails: string[];
}

export const isIgniteTitle = (title: IgniteTitle) => {
  try {
    return validateIgniteTitle(title);
  } catch {
    return false;
  }
};

export const validateIgniteTitle = (argument: IgniteTitle) => {
  validateNotNull(argument, `Invalid IgniteTitle type, object is null`);
  hasNonEmptyStringProperty(argument, 'IgniteTitle', 'id');
  isPropertyWithVerify(
    argument.detail,
    validateTitleDetails,
    'Invalid IgniteTitle type, detail is not a TitleDetails'
  );
  isPropertyWithVerify(
    argument.projectState,
    validateProjectState,
    'Invalid IgniteTitle type, projectState is not a ProjectState'
  );
  if (argument.productionStatus != null) {
    isPropertyWithVerify(
      argument.productionStatus,
      validateProductionStatus,
      'Invalid IgniteTitle type, productionStatus is not a ProductionStatus'
    );
  }
  if (argument.archival != null) {
    isPropertyWithVerify(
      argument.archival,
      validateArchival,
      'Invalid IgniteTitle type, archival is not a ArchivalMetadata'
    );
  }
  isPropertyWithVerify(
    argument.createdBy,
    validateUserInfo('IgniteTitle', 'createdBy'),
    'Invalid IgniteTitle type, createdBy is not a UserInfo'
  );
  hasNonEmptyStringProperty(argument, 'IgniteTitle', 'createdAt');
  isPropertyWithVerify(
    argument.modifiedBy,
    validateUserInfo('IgniteTitle', 'modifiedBy'),
    'Invalid IgniteTitle type, modifiedBy is not a UserInfo'
  );
  hasNonEmptyStringProperty(argument, 'IgniteTitle', 'modifiedAt');
  isArrayPropertyWithVerify<Note>(
    argument.notes,
    validateNote,
    'Invalid IgniteTitle type, notes is not a Note[]'
  );
  return true;
};

export interface IgniteTitleMin {
  id: string;
  titleType: string;
  name?: string;
  subName?: string;
  producers: string[];
  transmission?: StartDateRange;
  targetDate?: string;
  projectStatus: ProjectStatus;
  productionStatus?: string;
  team: string;
  archival?: ArchivalMetadata;
  createdBy: UserInfo;
  createdAt: string;
  modifiedBy: UserInfo;
  modifiedAt: string;
}

export interface IgniteTitleFilterParams {
  sortBy: string;
  order: string;
  genre: string;
  lastId: string;
  count: string;
  showArchived?: Nullable<string>;
  targetPeriod?: Nullable<string>;
  projectStatus: Nullable<(string | null)[]>;
  productionStatus: Nullable<(string | null)[]>;
  producerId: Nullable<(string | null)[]>;
  team: Nullable<(string | null)[]>;
}

export const isIgniteTitleMin = (title: IgniteTitleMin) => {
  try {
    return validateIgniteTitleMin(title);
  } catch {
    return false;
  }
};

export const validateIgniteTitleMin = (argument: IgniteTitleMin) => {
  validateNotNull(argument, `Invalid IgniteTitleMin type, object is null`);
  hasNonEmptyStringProperty(argument, 'IgniteTitleMin', 'id');
  hasNonEmptyStringProperty(argument, 'IgniteTitleMin', 'titleType');

  if (argument.name) {
    hasNonEmptyStringProperty(argument, 'IgniteTitleMin', 'name');
  }

  if (argument.subName) {
    hasNonEmptyStringProperty(argument, 'IgniteTitleMin', 'subName');
  }

  isNonEmptyArrayPropertyWithVerify(
    argument.producers,
    isNonEmptyString,
    `Invalid TitleDetailsMin.producers type, should be NonEmptyArray of strings`
  );

  if (argument.transmission) {
    isPropertyWithVerify(
      argument.transmission,
      validateStartDateRangeWithUnitType,
      `Invalid TitleDetailsMin.transmission type, should be StartDateRange`
    );
  }

  if (argument.targetDate) {
    hasNonEmptyStringProperty(argument, 'IgniteTitleMin', 'targetDate');
  }

  validateProjectStatus(argument.projectStatus);

  if (argument.productionStatus) {
    hasNonEmptyStringProperty(argument, 'IgniteTitleMin', 'productionStatus');
  }

  hasNonEmptyStringProperty(argument, 'IgniteTitleMin', 'team');

  if (argument.archival) {
    isPropertyWithVerify(
      argument.archival,
      validateArchival,
      `Invalid IgniteTitleMin.archival type, should be ArchivalMetadata`
    );
  }

  isPropertyWithVerify(
    argument.createdBy,
    validateUserInfo('IgniteTitleMin', 'createdBy'),
    `Invalid IgniteTitleMin.createdBy type, should be UserInfo`
  );

  hasNonEmptyStringProperty(argument, 'IgniteTitleMin', 'createdAt');

  isPropertyWithVerify(
    argument.modifiedBy,
    validateUserInfo('IgniteTitleMin', 'modifiedBy'),
    `Invalid IgniteTitleMin.modifiedBy type, should be UserInfo`
  );

  hasNonEmptyStringProperty(argument, 'IgniteTitleMin', 'modifiedAt');

  return true;
};
